/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Pressable,
  Image,
  SectionList,
} from 'react-native';

//Modules

//Components
import Loading from '../../components/general/loading.js'
import Tabs from '../../components/general/tabs.js'
import SectionHeader from '../../components/general/sectionheader.js'
import SectionItem from '../../components/general/sectionitem.js'
import EmptyState from '../../components/general/emptystate.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      sections:[],
      loading:true,
      refreshing:true,
      format:'all',
      tabs:[],
      abbreviations:this.props.abbreviations,
      mounted:true,
      isLive:this.props.isLive,
    }
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentDidUpdate = async (prevProps) => {
    if(prevProps.isLive !== this.props.isLive){
      this.setState({isLive:this.props.isLive})
    }
  }

  componentWillUnmount = async () => {
    clearTimeout(this.refresh_data)
    this.setState({mounted:false})
  }

  setup = async (params, disableRefreshing) => {
    clearTimeout(this.refresh_data)

    var { id, formats } = this.props

    if(!disableRefreshing){
      this.setState({refreshing:true})
    }

    if(!params){
      params = {}
    }

    params.id = id
    // params.mode = 'match_info'
    // params.submode = 'stats'
    // params.isLive = this.state.isLive

    // params.url = 'http://127.0.0.1:5001/tennis-lwts/us-central1/gen2_match_info_web'
    params.url = 'https://gen2-match-info-web-ysvbugl7mq-uc.a.run.app'
    params.submode = 'stats'

    var data_r = await Functions.tnnsAPI(params)
    var data_all = data_r?.data || {}

    var { data, tabs, period, refresh_time } = data_all
    this.refresh_time(refresh_time)
    if(this.state.period){
      period = this.state.period
    }
    var sections = data[period]

    this.setState({data, sections, tabs, period, loading:false, refreshing:false})
  }

  changePeriod = async (period_data) => {
    var period = period_data.id
    await this.setState({period, refreshing:true})

    var { data } = this.state
    var sections = data[period]
    this.setState({sections, refreshing:false})
  }

  //AppState & Reload Controls
  refresh_time = (refresh_time) => {
    if(refresh_time){
      this.refresh_data = setTimeout(()=>{
        if(!this.state.app_backgrounded && this.state.mounted){
          this.setup(null, true)
        }
      }, refresh_time)
    }
  }

  onAppStateChange = (appState) => {
    var app_backgrounded = appState === 'background'
    this.setState({appState, app_backgrounded})
    if(appState === 'active'){
      //Restart the listener
      this.setup()
    }
  }

  //

  renderContent = () => {
    var { sections } = this.state
    return(
      <View style={{width:'100%', flex:1}} animation={'fadeIn'} duration={240}>
        <SectionList
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:0}}
          sections={sections || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          renderSectionHeader={this.renderSectionHeader}
          ListHeaderComponent={this.renderTabs}
          onScrollBeginDrag={this.props.onScroll}
          ListEmptyComponent={this.renderEmpty}
          initialNumToRender={20}
        />
      </View>
    )
  }

  renderEmpty = () => {
    return (
      <View style={{width:'100%'}}>
        <EmptyState string="No stats are currently available for this match"/>
      </View>
    )
  }

  renderItem = ({item, index}) => {
    return <SectionItem item={item} index={index} key={index}/>
  }

  renderSectionHeader = ({section}) => {
    var { abbreviations } = this.state
    var { title, type, players } = section
    return(
      <SectionHeader title={title} players={abbreviations || players} type={type}/>
    )
  }

  renderTabs = () => {
    var { loading, tabs } = this.state
    return(
      !loading && tabs?.length > 0 ?
      <View style={{width:'100%'}} animation={'fadeIn'} duration={240}>
        <Tabs tabs={tabs} onChange={this.changePeriod} active_tab={this.state.period} secondary/>
      </View>
      :
      null
    )
  }

  render = () => {
    var { loading } = this.state

    try{
      return (
        <View style={[{flex:1, width:'100%', minHeight:300}, MainStyles.flexStartCenter]}>
          {loading ? <Loading/> : this.renderContent()}
        </View>
      );
    }catch(e){
      return null
    }
  }

}
