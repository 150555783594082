import React, { Component } from 'react';

import ReactCountryFlag from "../../components/general/flag.js"
import { Link, StackActions } from '@react-navigation/native';

import { View, Text, Linking, Image, ActivityIndicator, Alert, Dimensions, Switch, Animated, FlatList, Picker, Pressable } from "react-native";

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import GLOBAL from '../../helpers/global.js'
import Functions from '../../helpers/functions.js'

//Module
// import ReactGA from 'react-ga';

var height = 100

const {
  convertIocCode,
  convertIso2Code,
  convertIso3Code
} = require("convert-country-codes");

const axios = require("axios");
var CircularJSON = require('circular-json')

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

var match_width = 260

var padding = 10
var height = 90
// var this.state.style.color_accent_1 = '#36B9F2'

var isMobile = dimensions.width < 800
var phone = dimensions.width < 800

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:GLOBAL?.widget_matches?.length > 0 ? false : true,
      x:0,
      tour:GLOBAL.widget_matches_tour || 'atpwta',
      matches:GLOBAL.widget_matches || [],
      style:null,
      missedUpdates:0,
      lastUpdate:0,
    };
    // this.sectionListRef = React.createRef()
  }

  componentDidMount = async () => {
    window.addEventListener('focus', this.handleFocus);
    window.addEventListener('blur', this.handleBlur);
    document.addEventListener('visibilitychange', this.handleVisibilityChange)

    // await this.getTournaments()
    // await this.setup_camLiveScores()
    var style = GLOBAL.style
    var theme = {}
    var fonts = {}

    if(this.props.external){
      isMobile = true
      style = GLOBAL.style_light
      GLOBAL.style = GLOBAL.style_light
      GLOBAL.darkMode = false

      // alert(this.props.theme)

      var themes = {
        ["TennisHead"]:{
          accent:'#36B9F2',
          backgroundColor:'white',
          color_border:'rgba(0,0,0,0.04)',
          color:'white',
        },
        ["infinity"]:{
          accent:'#36B9F2',
          backgroundColor:'white',
          color_border:'rgba(0,0,0,0.04)',
        },
        ["talktennis"]:{
          accent:'#00CA8C',
          backgroundColor:'white',
          color_border:'rgba(0,0,0,0.04)',
        },
        ["buzz"]:{
          accent:'#CAF34C',
          backgroundColor:'#000',
          color_border:'rgba(0,0,0,0.04)',
          hide_top_border:true,
        },
        ["tennisconnected"]:{
          accent:'#457B3B',
          backgroundColor:'white',
          color_border:'rgba(0,0,0,0.04)',
        },
        ["tennisnow"]:{
          accent:'#61AF4A',
          backgroundColor:'white',
          color_border:'rgba(0,0,0,0.04)',
        },
        ["tennisexpress"]:{
          accent:'#335A9E',
          backgroundColor:'white',
          color_border:'rgba(0,0,0,0.04)',
          color:'white',
        },
      }

      if(this.props.theme === 'buzz'){
        GLOBAL.fonts = {
          ultra:'Roboto-Black',
          black:'Roboto-Black',
          bold:'Roboto-Bold',
          semibold:'Roboto-Regular',
          medium:'Roboto-Medium',
          regular:'Roboto-Regular',
        }
      }

      themes.tennishead = themes["TennisHead"]

      theme = themes[this.props.theme] || {}

      try{
        var route_style = this.props.style || ""
        if(route_style){
          route_style = route_style.split("-").join('"')
          theme = JSON.parse(route_style)
        }
      }catch(e){
        console.log(e)
      }
    }
    // console.log(this.props.theme)
    this.setState({style, theme, fonts})

    if(GLOBAL.hideNavbar){
      GLOBAL.hideNavbar()
    }

    await this.setup(GLOBAL.widget_matches_tour || 'atp', true)

    if(GLOBAL.hideNavbar){
      GLOBAL.hideNavbar()
    }
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.style !== this.props.style){
      this.setState({theme:this.props.style})
    }
  }

  componentWillUnmount = async () => {
    clearTimeout(this.updateData)
    clearTimeout(this.refresh_data)
    this.updateData = null
    this.refresh_data = null
    window.removeEventListener('focus', this.handleFocus);
    window.removeEventListener('blur', this.handleBlur);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  }

  openMatch = (data) => {
    // GLOBAL.navigation.push('Match', {id:data.docId})
    Functions.openMatch(data, null, this.props.external)
  }

  viewAll = () => {
    if(this.props.theme === 'TennisHead'){
      window.open("https://tennishead.net/live-tennis-scores/","_parent")
    }else{
      Linking.openURL("https://tnnslive.com/")
    }

  }

  //
  handleFocus = () => {
    // console.log('focus')
  }

  handleBlur = () => {
    // console.log('blur')
  }

  handleVisibilityChange = () => {

    const time_compare = (1 * 60 * 1000)

    console.log('Visibility changed to: '+document.visibilityState, "Seconds to compare: "+time_compare/1000+" seconds")

    if(document.visibilityState === 'visible'){
      //Trigger an update if the data was updated more than a minute ago
      if((Date.now() - this.state.lastUpdate) > time_compare){
        console.log('Viewing page and data is stale, asking for refresh')
        this.setup(null, null, null, true)
      }else{
        console.log('Viewing page but data is fresh, wait for next update')
      }

    }
  }

  //
  setup = async (tour, emptyRun) => {
    clearTimeout(this.updateData)
    clearTimeout(this.refresh_data)

    if(!tour){
      tour = this.state.tour
    }

    var tourRef = 'atp'
    if(tour === 'atp'){tourRef = 'atp'}
    if(tour === 'wta'){tourRef = 'wta'}
    if(tour === 'challenger'){tourRef = 'challenger'}
    if(tour === 'itf'){tourRef = 'itf'}

    GLOBAL.widget_matches_tour = tourRef

    //Get completed ATP matches (demo)

    // var urls = [
      // "https://us-central1-tennis-lwts.cloudfunctions.net/api_getTourLiveMatches?="+tourRef+"?=visual?=Pacific/Auckland?=en",
    //   "https://us-central1-tennis-lwts.cloudfunctions.net/api_web_getTourMatches?="+tourRef+"?=completed?=visual?=Pacific/Auckland?=en"
    // ]

    var matches_raw = []
    // await Promise.all(urls.map(async url => {
    //   var data = await this.firestoreAPI(url)
    //   try{ matches_raw = matches_raw.concat(data[0].data || []) }catch(e){console.log(e)}
    // }))

    let url = `https://gen2-banner-api-ysvbugl7mq-uc.a.run.app?=${tourRef}`

    let params = {
      tourRef,
      url,
    }
    let data = await Functions.tnnsAPI(params)

    if(data?.live){
      matches_raw.push(...data.live[0]?.data || [])
    }
    if(data?.completed){
      matches_raw.push(...data.completed[0]?.data || [])
    }


    //Get the daily matches


    //Filter to live and 


    //Valid matches
    //Promise all matches
    var matches_finished = []
    var matches_live = []

    await Promise.all(matches_raw.map(async (match, index) => {
      var matchObj = {
        id:match.id,
        set_scores:match.set_scores,
        strings:match.strings || Array(4).fill(''),
        competitors:match.competitors,
        winner_id:match.winner_id,
        docId:match.docId,
        isLive:!match.winner_id,
        tournament:match.tournament,
        rank:match.finishedAt || match.start_time_timestamp,
      }

      if(matchObj.competitors){
        //Tidy the names
        if(!match.competitors[0].name.includes("/")){
          //Singles, clean names
          var p1 = match.competitors[0].name.split(" ")
          var p2 = match.competitors[1].name.split(" ")

          p1.shift()
          p2.shift()

          match.competitors[0].name = p1.join(" ")
          match.competitors[1].name = p2.join(" ")
        }

        if(matchObj.isLive){
          matches_live.push(matchObj)
        }else{
          matches_finished.push(matchObj)
        }
      }

    }))

    await matches_finished.sort((a, b) => (a.rank < b.rank) ? 1 : -1);
    await matches_live.sort((a, b) => (a.rank < b.rank) ? 1 : -1);

    var matches = matches_live.concat(matches_finished)

    // console.log('Matches fetched', tour, tourRef, matches.length)

    if(emptyRun && matches.length === 0){
      if(tourRef === 'atp'){
        this.changeTour('wta', true)
      }else if(tourRef === 'wta'){
        this.changeTour('challenger', true)
      }else if(tourRef === 'challenger'){
        this.changeTour('itf', false)
      }
    }else{
      GLOBAL.widget_matches = matches

      await this.setState({matches, loading:false, x:0, lastUpdate:Date.now()})

      var reload_time = (matches_live.length ? 90 : 180) * 1000 //s refresh
      this.refresh_time(reload_time)
    }

  }

  //AppState & Reload Controls
  refresh_time = (refresh_time) => {
    this.setState({refresh_time})
    // console.log('Will refresh in '+refresh_time/1000+' seconds')
    if(refresh_time){
      this.refresh_data = setTimeout(()=>{

        // console.log('Refresh 3')

        var update_data = true

        if(document.visibilityState === 'visible'){
          //Update, window is visible
          update_data = true
        }else if(this.state.missedUpdates > (1000 * 5 * 60 / refresh_time)){
          //Data is 5 mins old, update
          // console.log('Missed enough updates, update the data')
          update_data = true
        }else{
          //Window blurred, don't update
          update_data = false
        }

        // console.log('Refresh requested', 'Visbility: '+document.visibilityState, 'Missed updates: '+this.state.missedUpdates, "Updating: "+update_data)

        if(update_data){
          //Update
          this.setup()
          this.setState({missedUpdates:0})
        }else{
          //Missed
          //Increase the missed update count and wait for another refresh
          this.setState({missedUpdates:this.state.missedUpdates + 1})
          this.refresh_time(refresh_time)
        }


      }, refresh_time)
    }
  }

  changeTour = async (tour, emptyRun) => {
    await this.setState({tour, loading:true})
    this.setup(tour, emptyRun)
  }

  firestoreAPI = async (url) => {
    var data = null
    await axios.get(url)
    .then(async (response) => {
      //Clean up and remove @ symbols
      var stringifyRaw = CircularJSON.stringify(response)
      var stringify = stringifyRaw.replace(/@/g,"")
      var cleanResponse = CircularJSON.parse(stringify);
      data = cleanResponse.data
      return
    })
    .catch(async (error) => {
      console.log('Error', error)
    });

    return data
  }

  handleLayout = async ({nativeEvent}) => {
    var scores_width = nativeEvent.layout.width
    this.setState({scores_width})
  }

  handleScroll = async ({nativeEvent}) => {
    var x = nativeEvent.contentOffset.x
    this.setState({x})
  }

  pushScroll = async (push) => {
    var jump_x = this.state.scores_width / 1.25
    var x = 0
    if(push){
      x = this.state.x + jump_x
    }else{
      x = this.state.x - jump_x
    }
    this.sectionListRef.scrollToOffset({offset:x})
  }


  //TH Renders
  renderMain = () => {
    var { loading, theme } = this.state
    var color = this.state.theme.accent || GLOBAL.style.cardColor

    //Check if mobile mode
    if(isMobile){
      return(
        <View style={[{width:'100%', borderWidth:1, borderTopWidth:theme.hide_top_border ? 0 : 1, borderColor:GLOBAL.style.borderColor }, MainStyles.flexCenter]}>
          <View style={[MainStyles.flexRow, MainStyles.flexBetweenCenter, {width:'100%', backgroundColor:'transparent', borderBottomColor:this.state.theme.color_border || 'transparent', borderBottomWidth:1}]}>
          {this.renderTourSelector(height-2, color)}
          {this.props.external ? this.renderFooter(height-2, false) : null}
          </View>
          <View style={[{width:'100%', height, borderRadius:0, overflow:'hidden', backgroundColor:GLOBAL.style.backgroundColor}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            {this.renderButton(false, this.state.x === 0)}
            {!loading ? this.renderMatches() : <View style={[{flex:1}, MainStyles.flexCenter]}><ActivityIndicator size="small" color={this.state.style.color_font}/></View>}
            {this.renderButton(true, false)}
          </View>
          {this.renderPoweredBy()}
        </View>
      )
    }else{
      return(
        <View style={[{width:'100%', height, borderTopWidth:theme.hide_top_border ? 0 : 1, borderBottomWidth:1, borderBottomColor:GLOBAL.style.borderColor, overflow:'hidden', backgroundColor:GLOBAL.style.backgroundColor}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          {this.renderTourSelector(height-2, color)}
          {this.renderButton(false, this.state.x === 0)}
          {!loading ? this.renderMatches() : <View style={[{flex:1}, MainStyles.flexCenter]}><ActivityIndicator size="small" color={this.state.style.color_font}/></View>}
          {this.renderButton(true, false)}
          {this.props.external ? this.renderFooter(height-2, false) : null}
        </View>
      )
    }


  }

  renderButton = (right, inactive) => {
    return(
      <Pressable onPress={()=>this.pushScroll(right)} style={[{height:'100%', width:isMobile ? 24 : 32, backgroundColor:GLOBAL.colors.border, borderColor:GLOBAL.darkMode ? 'rgba(255,255,255,0.024)' : 'rgba(0,0,0,0.024)', borderWidth:0, borderRightWidth:1, borderLeftWidth:1}, MainStyles.flexCenter]}>
        <Image style={[{height:12, width:12, resizeMode:'contain', tintColor:this.state.style.color_font, opacity:inactive ? 0.2 : 1}, right ? {transform:[{scaleX:-1}]} : null]} source={require('../../icons/back.png')}/>
      </Pressable>
    )
  }

  renderTourSelector = (height, backgroundColor) => {
    var tours = [
      "ATP & WTA",
      "Challenger",
      "ITF"
    ]
    var activeTourIndex = 0
    var activeTour = tours[activeTourIndex]
    return(
      <>
      <View style={[{padding:isMobile ? 0 : padding, paddingRight:isMobile ? padding : padding, height:'100%', backgroundColor, zIndex:2, borderBottomWidth:0}, MainStyles.flexCenter]}>
        <Picker
          selectedValue={this.state.tour}
          style={{ height: 37, width: 105, fontFamily:GLOBAL.fonts.bold, fontSize:14, color:this.state.theme.color || this.state.style.color_font_accent, backgroundColor:'transparent', borderColor:isMobile ? 'transparent' : GLOBAL.style.borderColor, borderRadius:4, paddingHorizontal:5 }}
          onValueChange={(tour) => this.changeTour(tour)}
        >
          <Picker.Item label="ATP" value="atp"/>
          <Picker.Item label="WTA" value="wta"/>
          <Picker.Item label="Challenger" value="challenger" />
          <Picker.Item label="ITF" value="itf" />
        </Picker>
      </View>
      {
        // this.renderTriangle(height, backgroundColor)
      }
      </>
    )
  }

  renderTriangle = (height, backgroundColor) => {
    return(
      <View
      style={{
        zIndex:1,
        marginLeft:-height/2,
        marginRight:-height/2,
        transform:[{scaleY:-1}],
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderLeftWidth: height/2,
        borderRightWidth: height/2,
        borderBottomWidth: height,
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderBottomColor: backgroundColor,
      }}
      />
    )
  }

  renderMatches = () => {
    var { matches } = this.state

    if(matches.length === 0){
      return(
        <View style={[{flexGrow:1, zIndex:0}, MainStyles.flexCenter]}>
          <Text style={{fontFamily:GLOBAL.fonts.semibold, fontSize:14, color:this.state.style.color_font}}>
          No recent matches available.
          </Text>
        </View>
      )
    }else{
      return(
        <FlatList
          ref={ref => (this.sectionListRef = ref)}
          onScroll={this.handleScroll}
          onLayout={this.handleLayout}
          // ListHeaderComponent={this.renderSectionListHeader}
          // ListFooterComponent={this.renderFooter()}
          data={matches}
          style={{flexGrow:1, zIndex:0}}
          contentContainerStyle={{paddingLeft:0}}
          horizontal
          keyExtractor={(item, index) => item.id}
          renderItem={this.renderMatch}
          scrollEnabled={true}
          scrollEventThrottle={0}
        />
      )
    }

  }

  renderPoweredBy = () => {
    return(
      <Pressable onPress={this.viewAll} style={[{width:'100%', backgroundColor:GLOBAL.style.backgroundColor, paddingVertical:5, paddingHorizontal:GLOBAL.padding, borderTopWidth:1, borderTopColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:12, color:'rgba(0,0,0,0.33)', textTransform:'uppercase', letterSpacing:0.4}}>
        Powered by <Text style={{fontFamily:GLOBAL.fonts.bold, color:'black'}}>TNNSLIVE.COM</Text>
        </Text>
      </Pressable>
    )
  }

  renderFooter = (height, backgroundColor) => {
    var backgroundColor = GLOBAL.colors.border
    var string = this.props.theme === 'TennisHead' ? 'View all' : 'View all'

    return(
      <View style={[{flex:1, backgroundColor:this.state.theme.backgroundColor}, MainStyles.flexCenterEnd]}>
        <Pressable onPress={this.viewAll} style={[{padding, paddingHorizontal:padding*2, height:'100%', backgroundcolor:this.state.style.color_font, zIndex:3}, MainStyles.flexCenterStart]}>
          <Text style={{fontFamily:GLOBAL.fonts.semibold, fontSize:14, color:this.state.theme.color_view_all || this.state.theme.accent || this.state.style.color_accent_1}}>
          View all
          </Text>
        </Pressable>
      </View>
    )
  }

  renderTitle = (title, show) => {
    if(show){
      // return(
      //   <View style={[{padding}, MainStyles.flexCenter]}>
      //     <Text style={{fontFamily:GLOBAL.fonts.regular, color:this.state.style.color_font, fontSize:14}}>
      //     {title}
      //     </Text>
      //   </View>
      // )
      return null
    }else{return null}
  }

  renderMatch = ({index, item}) => {
    return(

      <Pressable onPress={()=>this.openMatch(item)} key={index} style={[{padding:padding*1, borderRightWidth:1, borderRightColor:GLOBAL.style.borderColor}, MainStyles.flexCenterStart]}>

        {({ hovered, focused, pressed }) => (
          <>
            <View style={[MainStyles.flexRow, MainStyles.flexBetweenCenter, {width:'100%', marginBottom:padding, opacity:hovered ? 1 : 1}]}>
              <Text style={[{fontSize:12, opacity:0.5, fontFamily:GLOBAL.fonts.regular, color:this.state.style.color_font}, {marginRight:padding}, !hovered ? {opacity:0.5, color:this.state.style.color_font} : {opacity:0}, isMobile ? {maxWidth:900} : null]} numberOfLines={1}>
              {item.strings[0]}
              </Text>
              <Text style={[{fontSize:12, opacity:0.5, fontFamily:GLOBAL.fonts.regular, color:this.state.style.color_font}, {position:'absolute', left:0}, hovered ? {opacity:1} : {opacity:0}]}>
              {!hovered ? '' : 'Tap for detailed match info'}
              </Text>
              <Text style={[{fontSize:12, opacity:0.5, fontFamily:GLOBAL.fonts.regular, color:this.state.style.color_font}, !hovered ? null : {opacity:0}]}>
              {isMobile ? '' : (item.strings[3] ? (item.strings[3].includes("Match Time: ") ? item.strings[3].split("Match Time: ")[1] : item.strings[3]) : '')}
              </Text>
            </View>

            <View style={[MainStyles.flexRow, MainStyles.flexBetweenCenter, {opacity:1, width:'100%'}]}>
              <View style={[MainStyles.flexCenterStart, {marginRight:padding*2}]}>
                {this.renderPlayer(item.competitors[0], item.winner_id)}
                <View style={{height:padding}}/>
                {this.renderPlayer(item.competitors[1], item.winner_id)}
              </View>
              {this.renderScores(item.set_scores, item.winner_id)}
            </View>

            {
              // !hovered ? null :
              // <Pressable onPress={()=>Linking.openURL(url)} style={[{backgroundColor:'rgba(0,0,0,0)', height:'100%', width:'100%', position:'absolute', top:0, left:0}, MainStyles.flexCenter]}>
              //   <Text style={{fontSize:14, opacity:1, color:this.state.style.color_font, fontFamily:GLOBAL.fonts.bold}}>
              //   View match info
              //   </Text>
              // </Pressable>
            }
          </>
        )}

      </Pressable>
    )
  }

  renderPlayer = (player, winner_id) => {
    var isWinner = player.id === winner_id
    var opacity = isWinner || !winner_id ? 1 : 0.5
    return(
      <View key={player.id} style={[{}, MainStyles.flexRow, MainStyles.flexCenter]}>
        <View style={{marginTop:0}}>
        {this.renderFlags(player.flags)}
        </View>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {opacity}]}>
          <Text style={[{fontFamily:GLOBAL.fonts.semibold, fontSize:phone ? 12 : 14, color:this.state.style.color_font}, isMobile ? {maxWidth:900} : null]} numberOfLines={1}>
          {player.name}
          </Text>
          {
            !player.seed ? null :
            <Text style={{opacity:0.5, marginLeft:padding/2, fontFamily:GLOBAL.fonts.regular, fontSize:phone ? 12 : 14, color:this.state.style.color_font}}>
            {player.seed}
            </Text>
          }
          {
            !player.serving ? null :
            <View style={{height:10, width:10, borderRadius:100, backgroundColor:GLOBAL.colors.volt, borderWidth:1, borderColor:'rgba(0,0,0,0.05)', marginLeft:padding/2}}/>
          }
        </View>
      </View>
    )
  }

  renderFlags = (flags) => {
    var width = phone ? 14 : 16
    var height = phone ? 10 : 12
    var ratio = 4

    var isDoubles = flags.length === 2

    var marginRight = isDoubles ? padding*1.5 : padding/2

    if(isDoubles){
      return(
        <View style={[{height, width:width, marginRight}, MainStyles.flexCenter]}>
          <ReactCountryFlag
            countryCode={flags[0]}
            svg
            style={{width, height, position:'absolute', top:-height/ratio, left:0}}
            title={flags[0]}
          />
          <ReactCountryFlag
            countryCode={flags[1]}
            svg
            style={{width, height, position:'absolute', bottom:-height/ratio, left:width/2}}
            title={flags[1]}
          />
        </View>
      )
    }else{
      return(
        <View style={{marginRight}}>
        <ReactCountryFlag
          countryCode={flags[0]}
          svg
          style={{
          width,
          height,
          }}
          title={flags[0]}
        />
        </View>
      )
    }
  }

  renderScores = (set_scores, winner_id) => {
    return(
      <View style={[MainStyles.flexRow, MainStyles.flexCenter, {marginRight:-padding/2}]}>
      {set_scores.map((item, index) => this.renderScore(item, index, set_scores.length, winner_id))}
      </View>
    )
  }

  renderScore = (set_score, index, num_sets, winner_id) => {
    var style = {fontFamily:GLOBAL.fonts.semibold, fontSize:phone ? 12 : 14, color:this.state.style.color_font}
    var s1 = set_score[0]
    var s2 = set_score[1]

    var final_set = index === (num_sets - 1)

    var opacity_1 = s1 >= s2 || (final_set && !winner_id) ? 1 : 0.5
    var opacity_2 = s2 >= s1 || (final_set && !winner_id) ? 1 : 0.5

    return(
      <View key={index} style={[{marginHorizontal:padding/2}, MainStyles.flexCenter]}>
        <Text style={[{marginBottom:padding/2, opacity:opacity_1}, style]}>
        {set_score[0]}
        </Text>
        <Text style={[{marginTop:padding/2, opacity:opacity_2}, style]}>
        {set_score[1]}
        </Text>
      </View>
    )
  }

  render() {
    var { loading, style } = this.state
    return style ? this.renderMain() : null
  }
}

export default Item;
