/* @flow */

import React, { PureComponent } from "react";
import {
  View,
  Text,
  SectionList,
  ActivityIndicator,
  Pressable,
} from "react-native";

//Modules

//Components
import MatchNew from "../../screens/matches/Match";
import Match from "./match.js";
import Loading from "../../components/general/loading.js";
import SectionHeader from "../../components/general/sectionheader.js";
import EmptyState from "../../components/general/emptystate.js";

//Helpers
import MainStyles from "../../helpers/mainstyles.js";
import Functions from "../../helpers/functions.js";
import GLOBAL from "../../helpers/global.js";
// import Pressable from 'react-native/Libraries/Components/Pressable/Pressable.js';

export default class MyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data || [],
      // header:this.props.header || null,
      loading: this.props.loading,
      refreshing: this.props.refreshing,
    };
  }

  componentDidMount = async () => {};

  componentDidUpdate = async (prevProps) => {
    if (prevProps.data !== this.props.data) {
      // console.log(this.props.data)
      this.setState({ data: this.props.data || [] });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
    if (prevProps.refreshing !== this.props.refreshing) {
      this.setState({ refreshing: this.props.refreshing });
    }
    // if(prevProps.header !== this.props.header){
    //   this.setState({header:this.props.header || null})
    // }
  };

  componentWillUnmount = async () => {};

  openTournament = (data) => {
    if (data.id) {
      GLOBAL.navigation.push("Tournament", { id: data.id });
    }
  };

  //
  renderItem = ({ item, index }) => {
    if (this.props.new) {
      return (
        <MatchNew
          data={item}
          index={index}
          hideVideos={this.props.hideVideos}
          onPressMatch={this.props.onPress}
          theme={this.props.theme}
          oddsFormat={this.props.oddsFormat}
        />
      );
    }
    return (
      <Match
        data={item}
        index={index}
        hideVideos={this.props.hideVideos}
        onPressMatch={this.props.onPress}
        theme={this.props.theme}
      />
    );
  };

  renderSectionHeader = ({ section }) => {
    var { title, subtitle, d, id, id_onPress } = section;
    var onPressHeader = null;
    if (id_onPress && id) {
      if (id_onPress === "tournament") {
        onPressHeader = () => this.openTournament({ title, id });
      }
    }
    if (this.props.new) {
      return this.renderSectionHeaderNew({ title, subtitle, d });
    }
    return <SectionHeader title={title} onPress={onPressHeader} />;
  };

  renderSectionHeaderNew = ({ title, subtitle, d }) => {
    return (
      <Pressable
        onPress={() => this.openTournament(d)}
        style={{
          width: "100%",
          paddingVertical: 8,
          backgroundColor: "rgba(245,245,245,1)",
          paddingHorizontal: 16,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            opacity: 0.87,
            fontFamily: "ClanOT-Black",
            textTransform: "uppercase",
            textAlign: "left",
          }}
        >
          {title}
        </Text>
        {subtitle && (
          <Text
            style={{
              fontSize: 11,
              marginLeft: 8,
              opacity: 0.5,
              fontFamily: "Inter-Semibold",
              marginTop: 0,
              textTransform: "uppercase",
              textAlign: "left",
            }}
          >
            {subtitle}
          </Text>
        )}
      </Pressable>
    );
  };

  renderLoading = (string) => {
    return <Loading string={string} />;
  };

  renderEmpty = () => {
    return (
      <View style={{ paddingBottom: 32 }}>
        <EmptyState />
      </View>
    );
  };

  renderSection = ({ item, index }) => {
    return (
      <View
        style={[{ width: "100%", marginBottom: 1 }, MainStyles.flexCenterStart]}
      >
        {this.renderSectionHeader({
          section: { title: item.title, subtitle: item.subtitle, d: item.d },
        })}
        {item.data.map((item2, index2) =>
          this.renderItem({ item: item2, index: index2 })
        )}
      </View>
    );
  };

  render = () => {
    var { loading, data, refreshing } = this.state;

    var maxWidth = 700;

    if (loading) {
      try {
        return this.renderLoading("Loading");
      } catch (e) {
        return null;
      }
    } else {
      // if (GLOBAL.isMobile) {
      //   return (
      //     <SectionList
      //       ListHeaderComponent={this.props.header}
      //       style={{ width: "100%", flex: 1 }}
      //       contentContainerStyle={{ width: "100%", paddingBottom: 32 }}
      //       sections={refreshing ? [] : data || []}
      //       keyExtractor={(item, index) => item.id}
      //       renderItem={this.renderItem}
      //       renderSectionHeader={this.renderSectionHeader}
      //       ListEmptyComponent={refreshing ? null : this.renderEmpty}
      //       stickySectionHeadersEnabled
      //       // refreshControl={this.props.refreshControl}
      //     />
      //   );
      // }
      // try{
      return (
        <View style={{ width: "100%", backgroundColor: "transparent" }}>
          <View style={[{ width: "100%" }]} animation="fadeIn" duration={240}>
            {
              // <SectionList
              //   ListHeaderComponent={this.props.header}
              //   style={{width:'100%', flex:1}}
              //   contentContainerStyle={{width:'100%', paddingBottom:32}}
              //   sections={refreshing ? [] : data || []}
              //   keyExtractor={(item, index) => item.id}
              //   renderItem={this.renderItem}
              //   renderSectionHeader={this.renderSectionHeader}
              //   ListEmptyComponent={refreshing ? null : this.renderEmpty}
              //   refreshControl={this.props.refreshControl}
              // />
            }
            {this.props.header ? this.props.header() : null}
            <View
              style={{
                width: "100%",
                padding: GLOBAL.isMobile ? 0 : GLOBAL.padding * 0,
                overflow: "hidden",
                borderRadius: 0,
                borderWidth: 0,
                borderColor: GLOBAL.style.borderColor,
                backgroundColor: GLOBAL.isMobile
                  ? null
                  : GLOBAL.style.cardColor,
              }}
            >
              {data.length === 0 && !loading && !refreshing
                ? this.renderEmpty()
                : data.map((item, index) =>
                    this.renderSection({ item, index })
                  )}
            </View>
          </View>
          {refreshing && !loading ? this.renderLoading("Updating") : null}
        </View>
      );
      // }catch(e){
      //   return null
      // }
    }
  };
}
