/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Pressable,
  Image,
  FlatList,
  ScrollView
} from 'react-native';

//Modules

//Components
import Loading from '../../components/general/loading.js'
import Tabs from '../../components/general/tabs.js'
import SectionHeader from '../../components/general/sectionheader.js'
import SectionItem from '../../components/general/sectionitem.js'
import EmptyState from '../../components/general/emptystate.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

var textStyle = {fontFamily:GLOBAL.fonts.regular, fontSize:13, color:GLOBAL.style.color, letterSpacing:0}

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      sections:[],
      loading:true,
      refreshing:true,
      format:'all',
      tabs:[],
      abbreviations:this.props.abbreviations,
      players:this.props.players,
      mounted:true,
      isLive:this.props.isLive,
    }
  }

  componentDidMount = async () => {
    textStyle = {fontFamily:GLOBAL.fonts.regular, fontSize:13, color:GLOBAL.style.color, letterSpacing:0}
    this.setup()
  }

  componentDidUpdate = async (prevProps) => {
    if(prevProps.isLive !== this.props.isLive){
      this.setState({isLive:this.props.isLive})
    }
  }

  componentWillUnmount = async () => {
    clearTimeout(this.refresh_data)
    this.setState({mounted:false})
  }

  setup = async (params) => {
    clearTimeout(this.refresh_data)

    var { id, formats } = this.props

    this.setState({refreshing:true})

    if(!params){
      params = {}
    }

    params.id = id
    params.mode = 'match_info'

    // params.url = 'http://127.0.0.1:5001/tennis-lwts/us-central1/gen2_match_info_web'
    
    params.submode = 'recap'
    params.isLive = this.state.isLive

    var data_r = await Functions.tnnsAPI(params)
    var data_all = data_r?.data || {}

    var { data, tabs, period, refresh_time } = data_all
    this.refresh_time(refresh_time)

    if(this.state.period){
      period = this.state.period
    }

    var sections = data[period]

    this.setState({data, sections, tabs, period, loading:false, refreshing:false})
  }

  changePeriod = async (period_data) => {
    var period = period_data.id
    await this.setState({period, refreshing:true})

    var { data } = this.state
    var sections = data[period]

    this.setState({sections, refreshing:false})
  }

  //AppState & Reload Controls
  refresh_time = (refresh_time) => {
    if(refresh_time){
      this.refresh_data = setTimeout(()=>{
        if(!this.state.app_backgrounded && this.state.mounted){
          this.setup()
        }
      }, refresh_time)
    }
  }

  onAppStateChange = (appState) => {
    var app_backgrounded = appState === 'background'
    this.setState({appState, app_backgrounded})
    if(appState === 'active'){
      //Restart the listener
      this.setup()
    }
  }

  //

  renderContent = () => {
    var { sections } = this.state
    return(
      <View style={{width:'100%', flex:1}} animation={'fadeIn'} duration={240}>
        <FlatList
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:32}}
          data={sections || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          // renderSectionHeader={this.renderSectionHeader}
          ListHeaderComponent={this.renderTabs}
          onScrollBeginDrag={this.props.onScroll}
          ListEmptyComponent={this.renderEmpty}
        />
      </View>
    )
  }

  renderEmpty = () => {
    return (
      <View style={{width:'100%'}}>
        <EmptyState string="No recap is currently available for this match"/>
      </View>
    )
  }

  //
  renderItem = ({item, index}) => {
    var { players } = this.state

    var points = item.data || []

    var even = index%2 == 0
    var backgroundColor = 'transparent'

    var scores = [item.scores[0].score,item.scores[1].score]
    var leader = 'tied'

    if(item.scores[0].score > item.scores[1].score){
      leader = players[0].name + " leads"
    }
    if(item.scores[1].score > item.scores[0].score){
      scores = [item.scores[1].score,item.scores[0].score]
      leader = players[1].name + " leads"
    }

    return(
      <View style={{width:'100%'}} key={index}>

        {this.renderGameHeadline(item)}

        <View style={[MainStyles.flexColumn, MainStyles.flexCenterStart, {paddingLeft:GLOBAL.padding, paddingVertical:GLOBAL.padding/4, overflow:'visible', backgroundColor}]}>

          <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            {this.renderGameScores(item.scores)}
            <ScrollView horizontal style={{flex:1}} contentContainerStyle={{paddingLeft:GLOBAL.padding, overflow:'visible', marginBottom:25, marginTop:25}} showsHorizontalScrollIndicator={false}>
            {points.map((item, index) => this.renderPoint(item, index))}
            </ScrollView>
          </View>

        </View>

      </View>
    )
  }

  renderGameHeadline = (item) => {
    var { players } = this.state

    var index = 0

    var points = item.data || []

    var backgroundColor = GLOBAL.style.background_alternating

    var scores = [item.scores[0].score,item.scores[1].score]
    var leader = 'tied'

    if(item.scores[0].score > item.scores[1].score){
      leader = players[0].name
    }
    if(item.scores[1].score > item.scores[0].score){
      scores = [item.scores[1].score,item.scores[0].score]
      leader = players[1].name
    }

    var fontSize = 20

    return(
      <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {width:'100%', padding:GLOBAL.padding, paddingVertical:GLOBAL.padding/1.5, backgroundColor}]}>
        <View style={[MainStyles.flexRow, MainStyles.flexBetweenCenter, {marginBottom:0}]}>
          <Text style={[textStyle,{fontSize, fontFamily:GLOBAL.fonts.bold}]}>
          {scores[0]}
          </Text>
          <Text style={[textStyle,{fontSize, fontFamily:GLOBAL.fonts.regular, opacity:0.33}]}>
          {"-"}
          </Text>
          <Text style={[textStyle,{fontSize, fontFamily:GLOBAL.fonts.bold}]}>
          {scores[1]}
          </Text>
        </View>
        <Text style={[textStyle,{fontSize:14, fontFamily:GLOBAL.fonts.regular, opacity:0.5, marginLeft:GLOBAL.padding/2, marginTop:4}]}>
        {leader}
        </Text>
      </View>
    )
  }

  renderGameScores = (item) => {
    return(
      <View style={[MainStyles.flexStartCenter, MainStyles.flexColumn]}>
        <View style={{paddingRight:GLOBAL.padding, borderRightColor:GLOBAL.style.borderColor, borderRightWidth:1}}>
          {this.renderGameScore(item[0], 0)}
          {this.renderPadding()}
          {this.renderGameScore(item[1], 1)}
        </View>
      </View>
    )
  }

  renderGameScore = (item, index) => {
    var { players } = this.state

    var player = players[index].abbreviation

    var serving = null
    var size = 10
    if(item.serving){
      serving =
      <View style={{height:size, width:size, borderRadius:100, backgroundColor:GLOBAL.style.ball, position:'absolute', right:-size*1.5}}/>
    }

    return(
      <View style={[MainStyles.flexCenter]}>
        {serving}
        <Text style={[textStyle,{}]}>
        {player}
        </Text>
      </View>
    )
  }

  renderPoint = (item, index) => {
    return(
      <View style={{marginRight:GLOBAL.padding}} key={index}>
        {this.renderPointScore(item, 0, true, 'home')}
        {this.renderPadding()}
        {this.renderPointScore(item, 1, false, 'away')}
      </View>
    )
  }

  renderPointScore = (item, index, top, id) => {
    var score = item.scores[index]
    if(score === 50){score = 'AD'}

    var winner = item.winner === id
    var style = winner ? {fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.color} : {fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color_secondary}

    var bp = item.bp === id
    var sp = item.sp === id
    var mp = item.mp === id

    return(
      <View style={[MainStyles.flexCenter]}>
        {bp || sp || mp ? this.renderPointMarker(bp, sp, mp, top) : null}
        <Text style={[textStyle, style, {}]}>
        {score}
        </Text>
      </View>
    )
  }

  renderPointMarker = (bp, sp, mp, top) => {

    var text = ''
    var backgroundColor = 'transparent'
    var color = 'white'
    if(mp){
      text = 'MP'
      backgroundColor = GLOBAL.style.ball
      color = '#121212'
    }else if(sp){
      text = 'SP'
      backgroundColor = GLOBAL.colors.teal
    }else if(bp){
      text = 'BP'
      backgroundColor = GLOBAL.colors.pink
    }

    var size = 18

    var buffer = -size - 6
    var margin = top ? {top:buffer} : {bottom:buffer}

    return(
      <View style={[{width:size, height:size, borderRadius:4, backgroundColor, position:'absolute'}, margin, MainStyles.flexCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.black, fontSize:10, color, letterSpacing:-0.5}}>
        {text}
        </Text>
      </View>
    )
  }

  renderPadding = () => {
    return(
      <View style={{height:GLOBAL.padding/2}}/>
    )
  }

  //

  renderSectionHeader = ({section}) => {
    var { abbreviations } = this.state
    var { title, type, players } = section
    return(
      <SectionHeader title={title} players={abbreviations || players} type={type}/>
    )
  }

  renderTabs = () => {
    var { loading, tabs } = this.state
    return(
      !loading && tabs?.length > 0 ?
      <View style={{width:'100%'}} animation={'fadeIn'} duration={240}>
        <Tabs tabs={tabs} onChange={this.changePeriod} active_tab={this.state.period} secondary/>
      </View>
      :
      null
    )
  }

  render = () => {
    var { loading } = this.state

    try{
      return (
        <View style={[{flex:1, width:'100%', minHeight:300}, MainStyles.flexStartCenter]}>
          {loading ? <Loading/> : this.renderContent()}
        </View>
      );
    }catch(e){
      return null
    }
  }

}
