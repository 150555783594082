/* @flow */

import React, { PureComponent } from "react";
import { View, Text, Button, Image, Pressable } from "react-native";

//Modules
import { Link } from "@react-navigation/native";
import { Helmet } from "react-helmet";

import { NavigationContainer } from "@react-navigation/native";
// import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

//Components
import Tabs from "../components/general/tabs.js";
import Background from "../components/general/background.js";
import Loading from "../components/general/loading.js";
import SEOTitle from "../components/general/seotitle.js";
import Match from "../components/matches/match.js";
import BottomNavbar from "../components/navigation/bottomnavbar.js";

import Tournaments from "../screens/matches/tournaments.js";
import H2H from "./match/h2hbios.js";
import Preview from "./match/preview.js";
import Recap from "./match/recap.js";
import Stats from "./match/stats.js";
import Watch from "./match/watch.js";
import Odds from "./match/odds.js";
import Form from "./player/form.js";

import Header from "../components/seo/header.js";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from "../helpers/mainstyles.js";
import GLOBAL from "../helpers/global.js";
import Functions from "../helpers/functions.js";
// import Pressable from 'react-native/Libraries/Components/Pressable/Pressable.js';

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeView: "Live",
      id: this.props?.id || this.props?.route?.params?.id,
      hideNav: this.props?.route?.params?.hideNav,
      watch: this.props.route?.params?.watch,
      mounted: true,
      description:
        "Live scores, stats, point by point recaps, commentary, streams and bios.",
      lastUpdate: 0,
    };
  }

  componentDidMount = async () => {
    if (this.state.hideNav && GLOBAL.hideNav) {
      GLOBAL.hideNav();
    }
    this.setup();

    window.addEventListener("focus", this.handleFocus);
    window.addEventListener("blur", this.handleBlur);
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  };

  componentWillUnmount = async () => {
    clearTimeout(this.refresh_data);
    this.refresh_data = null;
    window.removeEventListener("focus", this.handleFocus);
    window.removeEventListener("blur", this.handleBlur);
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  };

  //
  handleFocus = () => {
    // console.log('focus')
  };

  handleBlur = () => {
    // console.log('blur')
  };

  handleVisibilityChange = () => {
    const time_compare = this.state.refresh_time || 1 * 60 * 1000;

    // console.log('Visibility changed to: '+document.visibilityState, "Seconds to compare: "+time_compare/1000+" seconds")

    if (document.visibilityState === "visible") {
      //Trigger an update if the data was updated more than a minute ago
      if (Date.now() - this.state.lastUpdate > time_compare) {
        // console.log('Viewing page and data is stale, asking for refresh')
        this.setup();
      } else {
        // console.log('Viewing page but data is fresh, wait for next update')
      }
    }
  };

  setup = async () => {
    clearTimeout(this.refresh_data);

    var id = this.props?.id || this.props?.route?.params?.id;

    var params = {
      id,
      mode: "match",
    };

    var data_r = await Functions.tnnsAPI(params);
    var data = data_r?.data || {};

    var {
      tabs,
      tabs_sections,
      first_tab,
      match_card,
      match_card_daily,
      header,
      abbreviations,
      players,
      player_ids,
      notifications,
      favorite,
      isLive,
      refresh_time,
      stream_data,
      id,
      highlights,
      seo,
    } = data;

    this.refresh_time(refresh_time);

    // delete tabs.tab_chat;
    // console.log(tabs)

    if (this.state.watch && first_tab) {
      first_tab = "watch";
    }

    tabs = Object.values(tabs);
    await tabs.sort((a, b) => (a.rank > b.rank ? 1 : -1));

    // tabs_sections = [{id:'chat', title:'Live Chat'}].concat(tabs_sections) //Demo

    //Setup the SEO title
    //Demo string - Live Scores & Updates Roger Federer vs Rafael Nadal - ATP Cup - Date - TNNS Live
    //LIVE UPDATES & STANDINGS Daniil Medvedev - Alex De Minaur - ATP Cup Singles - 4 January 2022 - Eurosport
    var title =
      "Live Scores & Updates " +
      seo.p1 +
      " vs " +
      seo.p2 +
      " - " +
      seo.tournament +
      " " +
      seo.format +
      " - " +
      seo.date +
      " - TNNS Live";
    var description =
      seo.p1 +
      " vs " +
      seo.p2 +
      " live score, streams, prediction, match preview, h2h record, stats on " +
      seo.date +
      " - " +
      seo.tournament;

    var seo_published_time = seo.published_time;
    var seo_modified_time = seo.modified_time;

    if (this.props.navigation) {
      if (this.props.navigation.setOptions) {
        this.props.navigation.setOptions({ title });
      }
    }
    this.setState({ title, description });

    if (!this.state.loading) {
      //Refreshing data
      await this.setState({
        seo_title: title,
        highlights,
        seo_published_time,
        seo_modified_time,
        tabs,
        tabs_sections,
        match_card,
        match_card_daily,
        header,
        id,
        abbreviations,
        players,
        stream_data,
        isLive,
        loading: false,
        lastUpdate: Date.now(),
      });
    } else {
      await this.setState({
        seo_title: title,
        highlights,
        seo_published_time,
        seo_modified_time,
        tabs,
        tabs_sections,
        first_tab,
        activeView: first_tab,
        match_card,
        match_card_daily,
        header,
        id,
        abbreviations,
        players,
        stream_data,
        notifications,
        favorite,
        isLive,
        loading: false,
        lastUpdate: Date.now(),
      });
    }
  };

  changeTab = (tab) => {
    // console.log('tab', tab)
    this.setState({ activeView: tab.id });
  };

  //AppState & Reload Controls
  refresh_time = (refresh_time) => {
    this.setState({ refresh_time });
    // console.log('Will refresh in '+refresh_time/1000+' seconds')
    if (refresh_time) {
      this.refresh_data = setTimeout(() => {
        // console.log('Refresh 2')

        var update_data = true;

        if (document.visibilityState === "visible") {
          //Update, window is visible
          update_data = true;
        } else if (this.state.missedUpdates > (1000 * 5 * 60) / refresh_time) {
          //Data is 5 mins old, update
          // console.log('Missed enough updates, update the data')
          update_data = true;
        } else {
          //Window blurred, don't update
          update_data = false;
        }

        // console.log('Refresh requested', 'Visbility: '+document.visibilityState, 'Missed updates: '+this.state.missedUpdates, "Updating: "+update_data)

        if (update_data) {
          //Update
          this.setup();
          this.setState({ missedUpdates: 0 });
        } else {
          //Missed
          //Increase the missed update count and wait for another refresh
          this.setState({ missedUpdates: this.state.missedUpdates + 1 });
          this.refresh_time(refresh_time);
        }
      }, refresh_time);
    }
  };

  renderContent = () => {
    var { overlay } = this.props;
    var {
      loading,
      seo_title,
      seo_published_time,
      seo_modified_time,
      stream_data,
    } = this.state;

    if (overlay) {
      return loading ? <Loading /> : this.renderMain();
    } else {
      return (
        <>
          {
            // <Header
            //   title={this.state.title}
            //   description={this.state.description}
            // />
          }
          <Background
            navigation={this.props.navigation}
            page={"Scores"}
            rightPanel={
              this.state.highlights && {
                render: this.renderRight,
                title: "Match highlights",
                button: {
                  cta: "Watch highlights",
                  onPress: this.openVideo,
                },
              }
            }
          >
            {loading ? <Loading /> : this.renderMain()}
          </Background>
          <BottomNavbar page="Scores" />
        </>
      );
    }
  };

  renderStream = () => {
    var { stream_data } = this.state;
    return (
      <>
        <Watch data={stream_data} player_only width={GLOBAL.column_width} />
      </>
    );
  };

  renderHeader = () => {
    var { header, match_card } = this.state;
    return (
      <View
        style={[
          {
            width: "100%",
            paddingVertical: GLOBAL.padding * 2,
            backgroundColor: GLOBAL.style.color,
          },
          MainStyles.flexCenter,
        ]}
      >
        <View
          style={[
            { width: "100%", maxWidth: GLOBAL.maxWidth },
            MainStyles.flexRow,
            MainStyles.flexStartCenter,
          ]}
        >
          <View
            style={[
              { flex: 1, paddingHorizontal: GLOBAL.padding },
              MainStyles.flexCenterStart,
            ]}
          >
            <Text
              style={{
                fontSize: 24,
                color: GLOBAL.style.backgroundColor,
                fontFamily: GLOBAL.fonts.bold,
                marginBottom: GLOBAL.padding / 4,
              }}
            >
              {header?.title}
            </Text>
            <Text
              style={{
                fontSize: 14,
                color: GLOBAL.style.backgroundColor,
                opacity: 0.65,
                fontFamily: GLOBAL.fonts.regular,
              }}
            >
              {header?.subtitle}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  renderMain = () => {
    var {
      activeView,
      tabs,
      activeView,
      id,
      isLive,
      abbreviations,
      players,
      match_card,
      stream_data,
    } = this.state;

    var render = null;
    if (activeView === "watch") {
      render = <Watch data={stream_data} />;
    } else if (activeView === "preview") {
      render = (
        <Preview tabLabel={"Preview"} forceTab={this.forceTab} id={id} />
      );
    } else if (activeView === "stats") {
      render = (
        <Stats
          tabLabel={"Stats"}
          id={id}
          abbreviations={abbreviations}
          isLive={isLive}
        />
      );
    } else if (activeView === "recap") {
      render = (
        <Recap tabLabel={"Recap"} id={id} players={players} isLive={isLive} />
      );
    } else if (activeView === "chat") {
      render = null;
    } else if (activeView === "preview") {
      render = (
        <Preview tabLabel={"Preview"} forceTab={this.forceTab} id={id} />
      );
    } else if (activeView === "odds") {
      render = (
        <Odds tabLabel={"Odds"} id={id} />
      );
    } else if (activeView === "h2h&bios") {
      render = <H2H tabLabel={"H2H & Bios"} id={id} />;
    } else if (activeView === "form") {
      render = <Form tabLabel={"Form"} players={players} />;
    }
    // render = (
    //   <Odds tabLabel={"Odds"} id={id} />
    // );

    return (
      <View style={[{ width: "100%" }, MainStyles.flexStartCenter]}>
        {
          // this.renderHeader()
        }
        <View
          style={{
            maxWidth: GLOBAL.maxWidth,
            width: "100%",
            borderRadius: 0,
            backgroundColor: GLOBAL.isMobile ? null : GLOBAL.style.cardColor,
            borderWidth: 0,
            borderColor: GLOBAL.style.borderColor,
          }}
        >
          <View
            style={[
              { width: "100%" },
              MainStyles.flexRow,
              MainStyles.flexCenterStart,
            ]}
          >
            <View style={{ flex: 1 }}>
              <View
                style={{
                  width: "100%",
                  borderBottomColor: GLOBAL.style.borderColorSecondary,
                  borderBottomWidth: 1,
                }}
              >
                {this.props.hideMatchCard ? null : (
                  <Match
                    data={match_card}
                    index={0}
                    screen={true}
                    hideControls={true}
                    disablePress
                  />
                )}
              </View>
              <View
                style={[
                  {
                    width: "100%",
                    borderBottomWidth: 1,
                    borderBottomColor: GLOBAL.style.borderColorSecondary,
                  },
                  MainStyles.flexCenter,
                ]}
              >
                <Tabs
                  tabs={tabs}
                  onChange={this.changeTab}
                  active_tab={activeView}
                />
              </View>
              {render}
            </View>
          </View>
        </View>
      </View>
    );
  };

  openVideo = () => {
    GLOBAL.showVideo(this.state.highlights.url);
  };

  renderRight = () => {
    var { highlights } = this.state;
    if (!highlights) {
      return null;
    }

    const img = highlights.thumbnail;
    const id = highlights.url;
    var id_youtube = id ? id.split("?v=")[1] : "";
    var url = "https://www.youtube.com/embed/" + id_youtube + "?autoplay=1&mute=1";

    return (
      <Pressable
        onPress={this.openVideo}
        style={{ width: "100%", paddingHorizontal: 0 }}
      >
        <Image
          style={{
            width: "100%",
            aspectRatio: 16 / 9,
            borderRadius: 0,
            marginTop: 0,
            resizeMode: "cover",
          }}
          source={{ uri: img }}
        />
        {
        //   <View
        //   style={{
        //     position: "absolute",
        //     top: 0,
        //     width: "100%",
        //     height: "100%",
        //     backgroundColor: "rgba(0,0,0,0)",
        //   }}
        // >
        //   <View
        //     style={[{ width: "100%", height: "100%" }, MainStyles.flexCenter]}
        //   >
        //     <iframe
        //       width={"100%"}
        //       height={"100%"}
        //       src={url}
        //       title="YouTube video player"
        //       frameborder="0"
        //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; full-screen"
        //       allowfullscreen="1"
        //       autoplay="1"
        //       mute="1"
        //     ></iframe>
        //   </View>
        // </View>
        }
      </Pressable>
    );
  };

  render = () => {
    // const linking = {
    //   prefixes: ['https://downloadtennis.com', 'https://www.downloadtennis.com', "https://tnnsweb.web.app/", 'tennisrn://', "http://localhost:3001", "https://tnnslive.com", "https://www.tnnslive.com"],
    //   config: {
    //     screens: {
    //       TournamentLive:'',
    //     }
    //   },
    // }

    try {
      return this.renderContent();
    } catch (e) {
      return null;
    }
  };
}
