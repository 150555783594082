/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Pressable,
  Image,
  TextInput,
  Dimensions,
  Animated,
  SectionList
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {createFilter} from 'react-search-input'

//Components
// import Image from '../general/image.js'
import Tournament from '../tournament/tournament.js'
import Player from '../players/player.js'
import Footer from './footer.js'
import Icon from '../general/icon.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'


const purple = '#805EFF'

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

var items = [
  {title:'Latest', link:'/news', page:'News', icon:'news'},
  {title:'Scores', link:'/', page:'Home', icon:'scoreboard'},
  {title:'Tournaments', link:'/tournaments', page:'Tournaments', icon:'court'},
  {title:'Rankings', link:'/rankings', page:'Rankings', icon:'podium'},
  // {title:'App', link:'/download', page:'Download'},
]

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      darkMode:GLOBAL.darkMode,
      showBackground:this.props.showBackground,
      searchTerm:'',
      showSearch:false,
      loadingSearch:true,
      tournaments_all:[],
      players_all:[],
      showMenu:false,
    }
  }

  async componentDidMount(){
    GLOBAL.hideNav = this.hideNav
    GLOBAL.clearSearch = this.clearInput
  }

  async componentDidUpdate(prevProps){
    if(prevProps.showBackground !== this.props.showBackground){
      this.setState({showBackground:this.props.showBackground})
    }
  }

  async componentWillUnmount(){

  }

  hideNav = () => {
    this.setState({hideNav:true})
  }

  goHome = () => {
    this.props.navigation.push('Home')
  }

  showMenu = () => {
    this.setState({showMenu:!this.state.showMenu})
  }

  clearInput = () => {
    this.searchInput.clear()
    this.setState({searchTerm:''})
  }

  showSearch = () => {
    this.setState({showSearch:!this.state.showSearch})
  }

  openPage = (page) => {
    // console.log(this.props.navigation)
    // console.log('Navigate', page)
    GLOBAL.page = page
    GLOBAL.navigation.push(page)
  }

  renderTab = (item, index) => {
    var active = this.props.page ? this.props.page === item.page : GLOBAL.page === item.page
    const tintColor = active ? purple : GLOBAL.style.color
    const fallbackOpacity = 0.5
    return(
      <Pressable onPress={()=>this.openPage(item.page)} style={[{flex:1, opacity: active ? 1 : fallbackOpacity, marginTop:-2, marginBottom:8}, MainStyles.flexCenter]}>
        <Icon icon={item.icon} filled={active} color={tintColor}/>
        <Text style={{color:tintColor, fontSize:10, fontFamily:"Inter-Medium", marginTop:0}}>
        {item.title}
        </Text>
      </Pressable>
      )
  }

  render = () => {
    if(GLOBAL.displayMode === 'mobile' && !this.state.hideNav){
      return(
        <View style={[{backgroundColor:GLOBAL.style.cardColor, paddingBottom:4, paddingTop:8, width:'100%', borderTopColor:GLOBAL.style.borderColor, borderTopWidth:1}, MainStyles.flexRow, MainStyles.flexCenter]}>
          {items.map((item, index) => this.renderTab(item, index))}
        </View>
      )
    }else{return null}

  }

}
