import { useNavigation, useRoute } from "@react-navigation/native";
import React from "react";

import { View, Text, StyleSheet } from "react-native";
import { Helmet } from "react-helmet";

import Match from "./match";

const MatchNew = () => {
  const route = useRoute();
  const params = route?.params || {};
  const { id, tournament, mode, players, round, season } = params;

  //SEO props
  const title = `Federer vs Nadal, US Open Quarterfinals 2024 | TNNS`;
  const description = `Live scores, updates, and stats for Federer vs Nadal in the 2024 US Open Quarterfinals. Follow the action live on TNNS.`;
  const keywords = `Federer vs Nadal, US Open 2024, tennis live scores, quarterfinals, TNNS`;

  const rich_content = {
    "@context": "https://schema.org",
    "@type": "SportsEvent",
    name: "Federer vs Nadal - US Open Quarterfinals 2024",
    startDate: "2024-09-05T19:00:00-04:00",
    location: {
      "@type": "Place",
      name: "Arthur Ashe Stadium",
      address: "Flushing Meadows, New York, NY",
    },
    sport: "Tennis",
    competitor: [
      {
        "@type": "Person",
        name: "Roger Federer",
        url: "https://www.tnnslive.com/players/roger-federer/",
      },
      {
        "@type": "Person",
        name: "Rafael Nadal",
        url: "https://www.tnnslive.com/players/rafael-nadal/",
      },
    ],
  };

  const text_content = JSON.stringify(rich_content)

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="description" content={description} />
        <meta
          name="keywords"
          content={keywords}
        />
        <meta
          property="og:title"
          content={title}
        />
        <script type="application/ld+json">
          {text_content}
        </script>
      </Helmet>
      <View style={styles.container}>
        <Text>{`Match: ${id} at ${tournament} in ${season}`}</Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default MatchNew;
