/* @flow */

import React, { Component } from 'react';

import {
  View,
  Text,
  Image,
  Pressable,
} from 'react-native';

//Modules
import Background from '../general/background.js'


//
import MainStyles from '../../helpers/mainstyles.js'
import GLOBAL from '../../helpers/global.js'

export default class Item extends Component {

  render = () => {
    // return (
    //   <View style={[{flex:1, width:'100%', height:300, paddingTop:GLOBAL.padding*3}, MainStyles.flexCenter]}>
    //     <Image style={{height:80, width:100, marginBottom:GLOBAL.padding, resizeMode:'contain', tintColor:GLOBAL.style.color, opacity:0.4}} source={require('../../assets/images/court-gradient.png')}/>
    //     <Text style={{fontFamily:GLOBAL.fonts.medium, color:GLOBAL.style.color, fontSize:12}}>
    //     {this.props.string || "No matches found"}
    //     </Text>

    //     {
    //       !this.props?.button ? null :
    //       <Pressable onPress={this.props?.button?.onPress} style={[{height:32, borderRadius:4, borderColor:GLOBAL.style.borderColor, borderWidth:1, backgroundColor:GLOBAL.style.borderColor, paddingHorizontal:GLOBAL.padding/1.5, marginTop:GLOBAL.padding*2}, MainStyles.flexCenter]}>
    //         <Text style={{fontSize:12, textTransform:'uppercase', fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.color}}>
    //         {this.props?.button?.title}
    //         </Text>
    //       </Pressable>
    //     }
    //   </View>
    // );

    return (
      <View style={[{flex:1, width:'100%', height:360, paddingVertical:GLOBAL.padding*(GLOBAL.isMobile ? 4 : 8)}, MainStyles.flexCenter]}>
        <View
          style={[
            {width: '100%'},
            {justifyContent: 'center', alignItems: 'center'},
          ]}>
          {
            <View
              style={{
                padding: 8,
                paddingHorizontal: 24,
                backgroundColor: 'rgba(0,0,0,0.04)',
                borderWidth: 0,
                borderColor: GLOBAL.style.borderColor,
                borderRadius: 8,
                opacity: 1,
              }}>
              <Text
                style={{
                  width: '100%',
                  color: GLOBAL.style.color,
                  fontFamily: 'ClanOT-Ultra',
                  fontSize: 24,
                  textAlign: 'center',
                  opacity: 0.85,
                }}>
                OUT
              </Text>
            </View>
          }

          {
            <Text
              style={{
                width: '100%',
                color: GLOBAL.style.color,
                fontFamily: GLOBAL.fonts.regular,
                fontSize: 13,
                textAlign: 'center',
                opacity: 0.5,
                marginTop: GLOBAL.padding,
              }}>
              {this.props.string || 'There are no matches to display'}
            </Text>
          }
        </View>
      </View>
    );
  }

}


