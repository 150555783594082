import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { Hoverable, Pressable } from "react-native-web-hover";
import ReactCountryFlag from "react-country-flag";
import Icon from "../../components/general/icon";
import Tooltip from "@mui/joy/Tooltip";
import MatchOverlay from "../../components/general/matchoverlaycard.js";
const flag_corrections = {
  GBR: "GB",
};

const pink = "#FA0A6C";
const purple = "#805EFF";

const Player = ({ data, theme }) => {
  if (!data) {
    return null;
  }
  const added_style =
    data.r === "w"
      ? (theme === "ltr" && { fontFamily: "Inter-Bold" }) || {}
      : {};
  return (
    <View style={[baseStyles.playerContainer, baseStyles.spacing, data.style]}>
      <Flags flags={data.f} />
      <Text style={[baseStyles.text, data.textStyle, added_style]}>
        {data?.n}
      </Text>
      {data.s && (
        <Text style={[baseStyles.text, baseStyles.seedText]}>{data?.s}</Text>
      )}
      {data.sv && <View style={baseStyles.ball} />}
    </View>
  );
};

const Set = ({ h, htb, a, atb, hs, as }) => {
  return (
    <View style={baseStyles.setContainer}>
      <Score score={h} tb={htb} style={hs} />
      <Score score={a} tb={atb} style={as} />
    </View>
  );
};

const Flags = ({ flags }) => {
  var render = null;

  var height = 11;
  let width = (height / 10) * 14;

  const borderRadius = 2;
  const borderWidth = 0;
  const borderColor = "rgba(0,0,0,0.1)";

  if (flags.length === 2) {
    height = 8;
    width = (height / 7.14) * 10;
    render = (
      <>
        <View style={{ position: "absolute", bottom: 4, left: -2 }}>
          {!flags[0] ? null : (
            <ReactCountryFlag
              countryCode={flag_corrections[flags[0]] || flags[0]}
              svg
              style={{ width, height, borderRadius, borderWidth, borderColor }}
              title={flags[0]}
            />
          )}
        </View>
        <View style={{ position: "absolute", top: -6, right: 10 }}>
          {!flags[1] ? null : (
            <ReactCountryFlag
              countryCode={flag_corrections[flags[1]] || flags[1]}
              svg
              style={{ width, height, borderRadius, borderWidth, borderColor }}
              title={flags[1]}
            />
          )}
        </View>
      </>
    );
  } else if (flags.length === 1 && flags[0]) {
    render = (
      <ReactCountryFlag
        countryCode={flag_corrections[flags[0]] || flags[0]}
        svg
        style={{ width, height, borderRadius, borderWidth, borderColor }}
        title={flags[0]}
      />
    );
  }

  return <View style={{ width: 26, marginBottom: 3 }}>{render}</View>;
};

const Score = ({ score, tb, style }) => {
  return (
    <View style={[baseStyles.scoreContainer, baseStyles.spacing, style]}>
      <Text style={[baseStyles.text, baseStyles.scoreText]}>{score}</Text>
      {tb && (
        <Text style={[baseStyles.text, baseStyles.tiebreakText]}>{tb}</Text>
      )}
    </View>
  );
};

const Button = ({ data, style }) => {
  // return null
  const onPress = () => {
    const GLOBAL = require("../../helpers/global");
    GLOBAL.default.showVideo(data.url);
  };
  const size = 28;
  return (
    <Pressable
      onPress={onPress}
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        // backgroundColor: "rgba(0,0,0,0.04)",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 12,
        marginRight: 16,
        overflow: "hidden",
      }}
    >
      <View
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: pink,
          opacity: 0.1,
        }}
      />
      <Icon
        icon={"play"}
        color={pink || "#FA0A6C"}
        size={13}
        style={{ marginLeft: 2 }}
      />
    </Pressable>
  );
};

const InfoPanel = ({ data, showInfo }) => {
  var backgroundColor = "#D1D2D3";
  return !showInfo ? null : (
    <View
      style={[
        {
          width: "100%",
          backgroundColor,
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
        },
      ]}
    >
      <View style={{ width: 16, height: "100%", backgroundColor }} />
      <View style={{ flex: 1 }}>
        <MatchOverlay id={data?.id} showBranding />
      </View>
    </View>
  );
};

const MatchCell = React.memo(
  ({ data, index, styles, theme, onPressMatch, oddsFormat }) => {
    const [showInfo, shouldShowInfo] = useState(false);
    const navigation = useNavigation();
    if (!data) {
      return null;
    }
    // console.log(data)
    const openMatch = () => {
      const matchId = data.id.replace("sr:sport_event:", "");

      // let isDifferentDomain = false;

      // try {
      //     // Attempt to access the parent window's location hostname
      //     if (window.top.location.hostname !== 'tnnslive.com') {
      //         isDifferentDomain = true;
      //         console.log('Different domain', window.top.location.hostname)
      //     }
      // } catch (error) {
      //     // If there's an error (CORS restriction), assume it's on a different domain
      //     isDifferentDomain = true;
      //     console.log('Different domain failed')
      // }
      if (onPressMatch) {
        shouldShowInfo(!showInfo);
      } else if (window.top !== window.self) {
        const matchUrl = `https://tnnslive.com/match/${matchId}`;
        window.open(matchUrl, "_blank");
      // } else if(data.slug){
      //   console.log(data.slug)
      //   // window.open(data.slug, "_tab")
      //   window.location.href = data.slug;
      } else {
        navigation.push("Match", {
          id: matchId,
        });
      }
    };
    //   console.log(data.od)

    const oddsWidth =
      oddsFormat === "american" ? 50 : oddsFormat === "fractional" ? 64 : 48;

    //   console.log(data.od)

    return (
      <>
        <Pressable
          style={({ hovered, focused, pressed }) => [
            baseStyles.container,
            hovered && { backgroundColor: "rgb(248,248,248)" },
          ]}
          onPress={openMatch}
        >
          <View style={baseStyles.playersContainer}>
            <Player theme={theme} data={data.p1} />
            <Player theme={theme} data={data.p2} />
          </View>
          {data.st && (
            <View style={baseStyles.stringsContainer}>
              <Text style={[baseStyles.text, baseStyles.stringsTitleText]}>
                {data.st.t}
              </Text>
              {data.st.s && (
                <Text style={[baseStyles.text, baseStyles.stringsSubtitleText]}>
                  {data.st.s}
                </Text>
              )}
            </View>
          )}
          {data.gs && (
            <View style={[baseStyles.setContainer, baseStyles.gameContainer]}>
              <Score score={data.gs.h} />
              <Score score={data.gs.a} />
            </View>
          )}
          {data.highlights && (
            <Button data={data.highlights} style={baseStyles.button} />
          )}
          {data.s && (
            <View style={baseStyles.setsContainer}>
              {data.s.map((set, index) => (
                <Set key={index} {...set} />
              ))}
            </View>
          )}
          {data.od && (
            <View style={[baseStyles.oddsContainer, { width: oddsWidth }]}>
            <Text
                  style={[
                    baseStyles.text,
                    baseStyles.oddsText,
                    baseStyles.spacing,
                    data.od.hc && { color: data.od.hc, opacity: 1 },
                  ]}
                >
                  {data.od.h}
                </Text>
                <View style={{height:1.5}}/>
              <Text
                style={[
                  baseStyles.text,
                  baseStyles.oddsText,
                  baseStyles.spacing,
                  data.od.ac && { color: data.od.ac, opacity: 1 },
                ]}
              >
                {data.od.a}
              </Text>
            </View>
          )}
        </Pressable>
        <InfoPanel data={data} showInfo={showInfo} />
      </>
    );
  }
);

const spacing = 8;
const spacingLarge = spacing * 2;
const spacingSmall = spacing / 2;

const fontSize = 13.5;
const fontFamily = "Inter-Medium";
const scoreFontFamily = "Inter-SemiBold";
const reducedOpacity = 0.65;

const setWidth = 22;
const ballSize = 8;

const lineColor = "rgba(0,0,0,0.05)";
const ballColor = "#8EE214" || "rgb(160, 250, 10)";

const baseStyles = StyleSheet.create({
  //General
  container: {
    width: "100%",
    paddingVertical: 10,
    flexDirection: "row",
    justitfyContent: "center",
    alignItems: "center",
    paddingHorizontal: spacingLarge,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: lineColor,
  },
  spacing: {
    marginVertical: spacingSmall,
  },
  text: {
    color: "black",
    fontSize,
    fontFamily,
    // fontWeight:'500'
  },

  //Players
  playersContainer: {
    alignItems: "flex-start",
    justifyContent: "center",
    flex: 1,
  },
  playerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    // flex:1,
  },
  seedText: {
    fontSize: fontSize - 2,
    opacity: reducedOpacity,
    marginBottom: 0,
    marginLeft: spacing,
  },
  ball: {
    height: ballSize,
    width: ballSize,
    borderRadius: ballSize / 2,
    backgroundColor: ballColor,
    marginLeft: spacing,
    marginBottom: ballSize / 2 - 0.5,
  },

  //Sets
  setsContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    marginRight: -6,
  },

  //Set
  setContainer: {
    justifyContent: "center",
    alignItems: "center",
    minWidth: setWidth,
  },

  //Game Score
  gameContainer: {
    marginRight: spacing,
    paddingRight: spacingLarge - 2,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: lineColor,
  },

  scoreText: {
    fontFamily: scoreFontFamily,
  },

  //Score
  scoreContainer: {
    justifyContent: "center",
    alignItems: "center",
  },

  tiebreakText: {
    position: "absolute",
    top: -7,
    right: -7,
    fontSize: fontSize - 4,
    opacity: 1,
  },

  //Strings
  stringsContainer: {
    justifyContent: "center",
    alignItems: "flex-end",
    minWidth: setWidth * 3,
  },
  stringsTitleText: {
    marginVertical: spacingSmall,
    opacity: reducedOpacity,
  },
  stringsSubtitleText: {
    marginVertical: spacingSmall,
    fontSize: fontSize - 2,
    opacity: reducedOpacity,
  },

  //Odds
  oddsContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
    marginTop:0.5,
    // marginLeft: spacingLarge,
    // width: 42,
  },
  oddsText: {
    fontSize: fontSize - 2,
    opacity: 0.55,
  },
});

const MatchCellCompleted = (props) => {
  return <MatchCell {...props} />;
};

export default MatchCellCompleted;
