/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Image,
  Pressable
} from 'react-native';

//Modules


//Components


//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      icon:this.props.icon,
      color:this.props.color || GLOBAL.style.color,
      size:this.props.size || 32,
      opacity:this.props.opacity || 1,
      filled:this.props.filled || false,
      onPress:this.props.onPress || null,
    }
  }

  async componentDidMount(){

  }

  async componentDidUpdate(prevProps){
    if(prevProps.icon !== this.props.icon){this.setState({icon:this.props.icon})}
    if(prevProps.color !== this.props.color){this.setState({color:this.props.color})}
    if(prevProps.size !== this.props.size){this.setState({size:this.props.size})}
    if(prevProps.opacity !== this.props.opacity){this.setState({opacity:this.props.opacity})}
    if(prevProps.filled !== this.props.filled){this.setState({filled:this.props.filled})}
    if(prevProps.onPress !== this.props.onPress){this.setState({onPress:this.props.onPress})}
  }

  async componentWillUnmount(){

  }

  render() {
    var { icon, color, size, opacity, filled, onPress } = this.state
    var { circled } = this.props

    var source = null
    var ratio = 1
    if(icon === 'home'){ filled ? source = require('../../assets/icons/home-filled.png') : source = require('../../assets/icons/home-filled.png') }
    if(icon === 'search'){ filled ? source = require('../../assets/icons/search-filled.png') : source = require('../../assets/icons/search.png') }
    if(icon === 'court'){ filled ? source = require('../../assets/icons/court-filled.png') : source = require('../../assets/icons/court-filled.png') }
    if(icon === 'trophy'){ filled ? source = require('../../assets/icons/trophy-filled.png') : source = require('../../assets/icons/trophy-filled.png') ; ratio = 0.92 }
    if(icon === 'podium'){ filled ? source = require('../../assets/icons/podium-filled.png') : source = require('../../assets/icons/podium-filled.png') }
    if(icon === 'news'){ source = require('../../assets/icons/news.png') }

    if(icon === 'spoilermode'){ source = require('../../assets/icons/spoilermode.png'); ratio = 1.14 }
    if(icon === 'settings'){ source = require('../../assets/icons/settings.png') }
    if(icon === 'tv'){ source = require('../../assets/icons/tv.png') }
    if(icon === 'calendar'){ source = require('../../assets/icons/calendar.png') }
    if(icon === 'back'){ source = require('../../assets/icons/back.png') }
    if(icon === 'down'){ source = require('../../assets/icons/down.png') }
    if(icon === 'forward'){ source = require('../../assets/icons/forward.png') }
    if(icon === 'share'){ source = require('../../assets/icons/share.png') }
    if(icon === 'close'){ source = require('../../assets/icons/close.png') }
    if(icon === 'play'){ source = require('../../assets/icons/play.png'); ratio = 0.8 }
    if(icon === 'link'){ source = require('../../assets/icons/link.png') }
    if(icon === 'audio'){ source = require('../../assets/icons/audio.png') }
    if(icon === 'time'){ source = require('../../assets/icons/time.png') }
    if(icon === 'replay'){ source = require('../../assets/icons/replay.png') }
    if(icon === 'website'){ source = require('../../assets/icons/website.png') }
    if(icon === 'cloud'){ source = require('../../assets/icons/cloud.png') }
    if(icon === 'microphone'){ source = require('../../assets/icons/commentary.png'); ratio = 1.4 }
    if(icon === 'scoreboard'){ filled ? source = require('../../assets/icons/scoreboard-filled.png') : source = require('../../assets/icons/scoreboard-filled.png') }

    if(icon === 'chevron-forward'){ source = require('../../assets/icons/chevron-forward.png') }

    if(icon === 'star'){ filled ? source = require('../../assets/icons/star-filled.png') : source = require('../../assets/icons/star.png') }
    if(icon === 'notification'){ filled ? source = require('../../assets/icons/notification-filled.png') : source = require('../../assets/icons/notification.png') }

    if(this.props.ratio){ratio = this.props.ratio}

    if(circled){size = 18}

    // if(!filled){
    //   opacity = 0.3
    // }

    if(onPress){
      try{
        return (
          <Pressable style={[{height:48, width:48},MainStyles.flexCenter, this.props.style]} onPress={onPress}>
            {
              circled ?
              <View style={[{height:30, width:30, borderRadius:20, borderWidth:1, borderColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}>
                <Image
                style={{height:size*ratio, width:size*ratio, tintColor:color, opacity, resizeMode:'contain'}}
                source={source}
                />
              </View>
              :
              <Image
              style={{height:size*ratio, width:size*ratio, tintColor:color, opacity, resizeMode:'contain'}}
              source={source}
              />
            }
          </Pressable>
        );
      }catch(e){
        return null
      }
    }else{

      try{
        return (
          <View style={[{height:size, width:size}, MainStyles.flexCenter, this.props.style]}>
          <Image
          style={{height:size*ratio, width:size*ratio, tintColor:color, opacity, resizeMode:'contain'}}
          source={source}
          />
          </View>
        );
      }catch(e){
        return null
      }
    }
  }


}
